import { type Toast, toast as setToast } from '@repo/ui/components/Toaster'
import { useEffect } from 'react'

export const useToaster = (toast?: Toast) => {
  useEffect(() => {
    if (!toast) return

    const { type, title, description, button } = toast

    setToast({
      title,
      description,
      type,
      button
    })
  }, [toast])
}
